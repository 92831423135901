exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-addresses-js": () => import("./../../../src/pages/addresses.js" /* webpackChunkName: "component---src-pages-addresses-js" */),
  "component---src-pages-bravo-js": () => import("./../../../src/pages/bravo.js" /* webpackChunkName: "component---src-pages-bravo-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-recrutement-js": () => import("./../../../src/pages/recrutement.js" /* webpackChunkName: "component---src-pages-recrutement-js" */)
}

